var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-treeview',{attrs:{"items":_vm.fileTree,"open":_vm.opened,"activatable":"","selectable":"","item-key":"fullName","open-all":""},on:{"update:open":function($event){_vm.opened=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.icon)?_c('v-icon',[_vm._v(" "+_vm._s(open ? _vm.mdiFolderOpen : _vm.mdiFolderOpen)+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(!item.editing)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(item.editing)?_c('v-text-field',{attrs:{"autofocus":""},model:{value:(item.newName),callback:function ($$v) {_vm.$set(item, "newName", $$v)},expression:"item.newName"}}):_vm._e()]}},(!_vm.$vuetify.breakpoint.smAndDown)?{key:"append",fn:function(ref){
var item = ref.item;
return [(!item.icon)?_c('span',[_vm._v(_vm._s(item.children.length)+" Files")]):_c('div',[_c('span',[_vm._v("["+_vm._s(item.size)+"]")]),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(item.progress)+"%")]),_c('span',{staticClass:"ml-4"},[_vm._v("[ "+_vm._s(_vm._f("priority")(item.priority))+" ]")]),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"accent white--text elevation-0 px-4 ml-2",attrs:{"fab":"","x-small":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTrendingUp))])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.priority_options),function(prio){return _c('v-list-item',{key:prio.value,attrs:{"link":""},on:{"click":function($event){return _vm.setFilePrio(item.id, prio.value)}}},[_c('v-icon',[_vm._v(_vm._s(prio.icon))]),_c('v-list-item-title',{staticClass:"caption"},[_vm._v(" "+_vm._s(prio.name)+" ")])],1)}),1)],1),(!item.editing)?_c('v-btn',{staticClass:"accent white--text elevation-0 px-4 ml-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencil))])],1):_vm._e(),(item.editing)?_c('v-btn',{staticClass:"accent white--text elevation-0 px-4 ml-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.renameFile(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiContentSave))])],1):_vm._e(),(item.editing)?_c('v-btn',{staticClass:"error white--text elevation-0 px-4 ml-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.togleEditing(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1):_vm._e()],1)]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }